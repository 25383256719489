import { useContext } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Dropdown, Icon } from "semantic-ui-react";

import classes from "./MainNavigation.module.css";
import mainImg from "../../assets/main.png";

const trigger = (
  <span>
    <Icon name="info" />
  </span>
);

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <header className={classes.header}>
      <img
        src={mainImg}
        alt="Main site logo."
        width="110"
        height="70"
        align="baselines"
      />
      <NavLink to="/">
        <span className={classes.logo}>Платформа Социолит</span>
      </NavLink>
      <nav>
        <ul>
          {!isLoggedIn && (
            <li>
              <NavLink activeClassName={classes.active} to="/auth">
                Login
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <>
              <li>
                <NavLink activeClassName={classes.active} to="/texts/0">
                  Романы
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={classes.active} to="/texts/1">
                  Рассказы
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={classes.active} to="/texts/3">
                Интервью
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={classes.active} to="/texts/6">
                Лит. институты
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={classes.active} to="/texts/8">
                Лит. коллоквиум
                </NavLink>
              </li>
              <li>
                <Dropdown className={classes["categories-dropdown"]} item text="Рубрики">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts">
                        Все тексты
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/0">
                        Русский романы
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/1">
                        Русский рассказы
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/2">
                        Нарративная проза
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/3">
                        Глубинные интервью
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/4">
                        Датасеты
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/5">
                        Библиотеки
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/6">
                        Литературные институты
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/7">
                        Архивы
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/8">
                        Литературный коллоквиум
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/texts/9">
                        ГУЛАГ
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown
                  className={classes["categories-dropdown"]}
                  item
                  trigger={trigger}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/ifora">
                      IFORA
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/aboutus">
                        О нас
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/links">
                        Ссылки
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/media">
                        Медиа
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink className={classes.category} to="/forum">
                        Форум
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <button onClick={logoutHandler}>Выйти</button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
