import classes from "./SingleEvent.module.css";

const SingleEvent = (props) => {
  return (
    <li key={props.id} className={classes.publication}>
      <p>{props.type}</p>
      <h2>{props.title}</h2>
      <h3>{props.description}</h3>
    </li>
  );
};

export default SingleEvent;
