import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";

import Button from "../UI/Button";

import classes from "./SearchInput.module.css";

const SearchInput = (props) => {
  const { defaultTags, onSearch } = props;
  const [enteredValue, setEnteredValue] = useState("");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (defaultTags.length !== 0) {
      setTags(defaultTags);
    }
  }, [defaultTags]);

  const searchHandler = () => {
    onSearch(tags, enteredValue);
  };

  // useEffect(() => {
  //   if (categoryId) {
  //     setTags([categories[categoryId]["tag"]]);
  //     onSearch(tags, enteredValue);
  //   }
  // }, [categoryId, tags, enteredValue, onSearch]);
  
  const searchInputChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
    }
    setEnteredValue(event.target.value);
  };

  const dropdownChangeHandler = (event, { value }) => {
    setTags(value);
  };

  return (
    <>
      <Dropdown
        id="tags"
        placeholder="Рубрики"
        fluid
        multiple
        search
        selection
        options={props.allTags}
        onChange={dropdownChangeHandler}
        value={tags}
      />
      <div className={classes["form-control"]}>
        <input type="text" onChange={searchInputChangeHandler} />
        <Button type="submit" onClick={searchHandler}>
          Search
        </Button>
      </div>
    </>
  );
};

export default SearchInput;
