import { Tab } from "semantic-ui-react";

import aboutUsImage from "../assets/aboutus.png";

import Events from "../components/AboutUs/Events/Events";
import News from "../components/AboutUs/News/NewsList";
import Publications from "../components/AboutUs/Publications/Publications";
import Structure from "../components/AboutUs/Structure/Structure";

const panes = [
  {
    menuItem: "Новости",
    render: () => (
      <Tab.Pane>
        <News />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Публикации",
    render: () => (
      <Tab.Pane>
        <Publications />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Семинары и конференции",
    render: () => (
      <Tab.Pane>
        <Events />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Рабочая группа",
    render: () => (
      <Tab.Pane>
        <Structure />
      </Tab.Pane>
    ),
  },
];

const AboutUs = () => {
  return (
    <>
      <h1 className="page-title">О нас</h1>
      <div className="line-holder">
        <img
          src={aboutUsImage}
          alt="About us foto."
          width="200"
          height="300"
          align="left"
        />
        <p>
          Компьютерная платформы «Социолит» включает в себя оцифрованные корпусы текстов, а также автоматическую систему, позволяющую обрабатывать и перманентно расширять их.
        </p>
        <p>
          Социолит включает базы данных (БД) и интервью, проведенные в процессе проекта, которые позволяют судить о характере взаимодействия литературы и общества в разные периоды развития литературного процесса.
        </p>
        <p>
          <a href="https://philology.hse.ru/soclit" target="_blank" rel="noreferrer">https://philology.hse.ru/soclit</a>
        </p>
      </div>
      <Tab panes={panes} />
    </>
  );
};

export default AboutUs;
