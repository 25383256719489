import Modal from "../UI/Modal";
import Button from "../UI/Button";

import classes from "./AddNewText.module.css";
import { useState, useContext, useEffect } from "react";
import { Dropdown, Radio } from "semantic-ui-react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import useHttp from "../hooks/useHttp";
import { getText, downloadFile, uploadFile } from "../lib/api";
import AuthContext from "../../store/auth-context";

import LoadingSpinner from "../UI/LoadingSpinner";

const escapeFileName = (fileName) => {
  let newFileName = fileName.replace("#", "");
  return newFileName;
}

const EditText = (props) => {
  debugger;
  const { textId } = props;
  const loadedText = props.texts.filter((item) => item.id === textId)[0];
  const [name, setName] = useState(loadedText.name);
  const [description, setDescription] = useState(loadedText.description);
  const [content, setContent] = useState(loadedText.content);
  let defaultTags = [];
  if (loadedText.tags) defaultTags = loadedText.tags;
  const [tags, setTags] = useState(defaultTags);

  let defaultContentType = "text";
  if (loadedText.contentType) {
    defaultContentType = loadedText.contentType;
  }
  const [contentType, setContentType] = useState(defaultContentType);
  const loadedFileName = loadedText.fileName;
  const [fileName, setFileName] = useState(loadedFileName);
  const [file, setFile] = useState();
  const [pdfFile, setPdfFile] = useState();

  const authCtx = useContext(AuthContext);
  const { token } = authCtx;
  const {
    sendRequest: sendGetTextRequest,
    status: getTextRequestStatus,
    data: textData,
  } = useHttp(getText);
  const {
    sendRequest: sendDownloadFileRequest,
    status: downloadFileRequestStatus,
    data: fileData,
  } = useHttp(downloadFile);
  const {
    sendRequest: sendUploadFileRequest,
    status: uploadFileRequestStatus,
    data: uploadFileName,
  } = useHttp(uploadFile);

  useEffect(() => {
    let requestData = {
      token,
      textId: textId,
    };
    sendGetTextRequest(requestData);
    if (loadedFileName) {
      let downloadFileRequestData = {
        token,
        fileName: loadedFileName,
      };
      sendDownloadFileRequest(downloadFileRequestData);
    }
  }, [
    textId,
    sendGetTextRequest,
    sendDownloadFileRequest,
    token,
    loadedFileName,
  ]);

  const isPending =
    getTextRequestStatus === "pending" ||
    downloadFileRequestStatus === "pending" ||
    uploadFileRequestStatus === "pending";

  useEffect(() => {
    if (textData) {
      setContent(textData["content"]);
    }
  }, [textData]);

  useEffect(() => {
    if (uploadFileRequestStatus !== "pending" && uploadFileName) {
      setFileName(uploadFileName);
    }
  }, [uploadFileRequestStatus, uploadFileName]);

  useEffect(() => {
    if (fileData) {
      setFile(fileData);
      setPdfFile(URL.createObjectURL(fileData));
    }
  }, [fileData]);

  const saveHandler = () => {
    props.onSaveText(
      props.textId,
      name,
      description,
      content,
      tags,
      contentType,
      fileName
    );
    props.onClose();
  };

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  };
  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };
  const contentChangeHandler = (event) => {
    setContent(event.target.value);
  };

  const dropdownChangeHandler = (event, { value }) => {
    setTags(value);
  };

  const contentTypeRadioHandler = (event, { value }) => {
    setContentType(value);
  };

  const chooseFileHandler = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPdfFile(URL.createObjectURL(selectedFile));
    }
  };

  const uploadHandler = () => {
    const uploadFileData = {
      token,
      file,
      fileName: escapeFileName(file["name"]),
    };
    sendUploadFileRequest(uploadFileData);
  };

  const deleteHandler = () => {
    setFileName("");
    setPdfFile(null);
  };



  return (
    <Modal onClose={props.onClose}>
      <div className={classes.control}>
        <label htmlFor="name">Название</label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={nameChangeHandler}
        />
        <label htmlFor="description">Описание</label>
        <input
          id="description"
          type="text"
          value={description}
          onChange={descriptionChangeHandler}
        />
        <label htmlFor="tags">Рубрики</label>
        <Dropdown
          id="tags"
          placeholder="Рубрики"
          fluid
          multiple
          search
          selection
          options={props.allTags}
          value={tags}
          onChange={dropdownChangeHandler}
        />
      </div>
      <div className={classes.control}>
        <Radio
          name="contentType"
          label="Текст"
          value="text"
          checked={contentType === "text"}
          onChange={contentTypeRadioHandler}
          className={classes.radio}
        />
        <Radio
          name="contentType"
          label="PDF"
          value="pdf"
          checked={contentType === "pdf"}
          onChange={contentTypeRadioHandler}
          className={classes.radio}
        />
        <Radio
          name="contentType"
          label="File"
          value="file"
          checked={contentType === "file"}
          onChange={contentTypeRadioHandler}
          className={classes.radio}
        />
      </div>
      {isPending && <LoadingSpinner />}
      {contentType === "text" && (
        <div className={classes.control}>
          <label htmlFor="text">Текст</label>
          <textarea id="text" value={content} onChange={contentChangeHandler} />
        </div>
      )}
      {contentType === "pdf" && (
        <div className={classes.control}>
          <div className={classes["flex-label"]}>
            <label htmlFor="chooseFile">Выберите файл для добавления: </label>
            <input
              id="chooseFile"
              name="chooseFile"
              type="file"
              onChange={chooseFileHandler}
            />
            <Button onClick={uploadHandler}>UPLOAD</Button>
          </div>
          <div className={classes["flex-label"]}>
            <label htmlFor="fileName">Имя добавленного файла: </label>
            <input
              id="fileName"
              name="fileName"
              type="text"
              readOnly
              value={fileName}
            />
            <a href={pdfFile} target="_blank" rel="noreferrer" download={fileName} className={classes["download-button"]}>DOWNLOAD</a>
            <Button onClick={deleteHandler}>DELETE</Button>
          </div>
          {pdfFile && (
            <div className={classes["control-div"]}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfFile} />
              </Worker>
            </div>
          )}
        </div>
      )}
      {contentType === "file" && (
        <div className={classes.control}>
          <div className={classes["flex-label"]}>
            <label htmlFor="chooseFile">Выберите файл для добавления: </label>
            <input
              id="chooseFile"
              name="chooseFile"
              type="file"
              onChange={chooseFileHandler}
            />
            <Button onClick={uploadHandler}>UPLOAD</Button>
          </div>
          <div className={classes["flex-label"]}>
            <label htmlFor="fileName">Имя добавленного файла: </label>
            <input
              id="fileName"
              name="fileName"
              type="text"
              readOnly
              value={fileName}
            />
            <a href={pdfFile} target="_blank" rel="noreferrer" download={fileName} className={classes["download-button"]}>DOWNLOAD</a>
            <Button onClick={deleteHandler}>DELETE</Button>
          </div>
        </div>
      )}
      <div className={classes.actions}>
        <Button onClick={saveHandler}>Save</Button>
        <Button additionalClass="cancel" onClick={props.onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default EditText;
