import SinglePublication from "./SinglePublication";

import classes from "./Publications.module.css";

const DUMMY_NEWS = [
  {
    id: "publication1",
    authors: "Vdovin Alexey",
    title: "Opaque Mind, Muteness, and Melodrama: Dmitry Grigorovich’s Invention of Peasant Subjectivity in The Village (1846)",
    reference: "Slavic and East European Journal",
  },
  {
    id: "publication2",
    authors: "Chentsova Alexandra, Avdeenko Nadezhda",
    title: "Сравнительное исследование куррикулума по словесности: поиск теоретической рамки",
    reference: "Вопросы образования",
  },
  {
    id: "publication3",
    authors: "Вьюгин Валерий",
    title: "Ностальгия по травме («Премиальная» литература 2019 года и советское прошлое)",
    reference: "Новое литературное обозрение. – 2022. № 6.",
  },
  {
    id: "publication4",
    authors: "Вьюгин Валерий",
    title: "«Слово не воробей... (Речь М. А. Шолохова на Втором Всесоюзном съезде писателей (1954-1956) в текстологическом измерении)»",
    reference: "Русская литература. – 2022.",
  }
];

const Publications = () => {
  return (
    <ul className={classes["publication-list"]}>
      {DUMMY_NEWS.map((item) => {
        return (
          <SinglePublication id={item.id} authors={item.authors} title={item.title} reference={item.reference} />
        );
      })}
    </ul>
  );
};

export default Publications;
