import { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import UserProfile from "./components/Profile/UserProfile";
import AboutUs from "./pages/AboutUs";
import AuthPage from "./pages/AuthPage";
import UnderContruction from "./pages/UnderContruction";
import HomePage from "./pages/HomePage";
import AuthContext from "./store/auth-context";
import Links from "./pages/Links";
import Texts from "./pages/Texts";
import Ifora from "./components/Other/ifora";

function App() {
  const authCtx = useContext(AuthContext);

  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        {!authCtx.isLoggedIn && (
          <Route path="/auth">
            <AuthPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <>
            <Route path="/profile">
              <UserProfile />
            </Route>
            <Route path="/aboutus">
              <AboutUs />
            </Route>
            <Route path="/forum">
              <UnderContruction />
            </Route>
            <Route path="/media">
              <UnderContruction />
            </Route>
            <Route path="/links">
              <Links />
            </Route>
            <Route path="/texts/new">
              <UnderContruction />
            </Route>
            <Route path="/texts/:categoryId">
              <Texts />
            </Route>
            <Route path="/texts" exact>
              <Texts />
            </Route>
            <Route path="/ifora">
              <Ifora />
            </Route>
          </>
        )}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
