import { useState, useEffect, useContext } from "react";
import AddText from "../AddText";

import TextsTable from "../TextsTable";
import AddNewTag from "./AddNewTag";

import useHttp from "../../hooks/useHttp";
import { addTag } from "../../lib/api";
import AuthContext from "../../../store/auth-context";

const TagsTab = (props) => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddingNewTag, setIsAddingNewTag] = useState(false);

  const itemsOnScreen = 10;
  let pagesCount = Math.ceil(props.allTags.length / itemsOnScreen);
  const tagsToShow = props.allTags.slice(
    (currentPage - 1) * itemsOnScreen,
    currentPage * itemsOnScreen
  );

  const changePageHandler = (page) => {
    setCurrentPage(page);
  };
  const showAddNewTag = () => {
    setIsAddingNewTag(true);
  };
  const hideAddNewTag = () => {
    setIsAddingNewTag(false);
  };

  const {
    sendRequest: sendAddRequest,
    status: addRequestStatus,
    data: newTagId,
  } = useHttp(addTag);

  const { setIsPending, allTags } = props;

  useEffect(() => {
    if (addRequestStatus === "pending") {
      setIsPending(true);
    } else {
      setIsPending(false);
      if (newTagId) {
        allTags.filter((item) => item.id === "addedTag")[0].id = newTagId;
      }
    }
  }, [addRequestStatus, setIsPending, newTagId, allTags]);
  const addNewTag = (value, text) => {
    setIsAddingNewTag(false);

    const tagData = {
      token,
      value,
      text,
    };

    sendAddRequest(tagData);
    props.allTags.push({ ...tagData, id: "addedTag" });
  };
  return (
    <>
      <section id="text-list" className="column pointer">
        <TextsTable
          texts={tagsToShow}
          pagesCount={pagesCount}
          currentPage={currentPage}
          onChangePage={changePageHandler}
          column1header="Мнемокод"
          column2header="Отображаемое имя"
          column1name="value"
          column2name="text"
        />
      </section>
      <section id="add-text" className="column">
        <AddText onClick={showAddNewTag} />
      </section>
      {isAddingNewTag && (
        <AddNewTag
          onClose={hideAddNewTag}
          onAddTag={addNewTag}
          allTags={props.allTags}
        />
      )}
    </>
  );
};

export default TagsTab;
