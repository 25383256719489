import { Table } from "semantic-ui-react";

const TextsTableRow = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick(props.id);
  };
  return (
    <Table.Row onClick={clickHandler}>
      <Table.Cell>{props.name}</Table.Cell>
      <Table.Cell>{props.description}</Table.Cell>
    </Table.Row>
  );
};

export default TextsTableRow;
