import { Table } from "semantic-ui-react";
import TextsTablePagination from "./TextsTablePagination";
import TextsTableRow from "./TextsTableRow";

const TextsTable = (props) => {
  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{props.column1header}</Table.HeaderCell>
          <Table.HeaderCell>{props.column2header}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.texts.map((item) => (
          <TextsTableRow
            key={item.id}
            id={item.id}
            name={item[props.column1name]}
            description={item[props.column2name]}
            onClick={props.onRowClick}
          />
        ))}
      </Table.Body>

      <Table.Footer>
        <TextsTablePagination
          key="pagination"
          currentPage={props.currentPage}
          pagesCount={props.pagesCount}
          onChangePage={props.onChangePage}
        />
      </Table.Footer>
    </Table>
  );
};

export default TextsTable;
