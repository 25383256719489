import { Table, Menu, Icon } from "semantic-ui-react";
import React from "react";
const TextsTablePagination = (props) => {
  const changePage = (page) => {
    if (page < 1) page = 1;
    if (page > props.pagesCount) page = props.pagesCount;
    return () => props.onChangePage(page);
  };

  const getPages = () => {
    let pages = [];
    for (let i = 1; i <= props.pagesCount; i++) {
      if ((i < props.currentPage - 3) || (i > props.currentPage + 3)) continue;
      if (i === props.currentPage)
        pages.push(
          <Menu.Item key={i} active as="a">
            {i}
          </Menu.Item>
        );
      else
        pages.push(
          <Menu.Item key={i} as="a" onClick={changePage(i)}>
            {i}
          </Menu.Item>
        );
    }
    return pages;
  };

  return (
    <Table.Row key="pagination">
      <Table.HeaderCell colSpan="3">
        <Menu floated="right" pagination>
          <Menu.Item as="a" icon onClick={changePage(props.currentPage - 1)}>
            <Icon name="chevron left" />
          </Menu.Item>
          {getPages()}
          <Menu.Item as="a" icon onClick={changePage(props.currentPage + 1)}>
            <Icon name="chevron right" />
          </Menu.Item>
        </Menu>
      </Table.HeaderCell>
    </Table.Row>
  );
};

export default TextsTablePagination;
