import { Tab } from "semantic-ui-react";
import { useEffect, useState, useContext } from "react";

import AllTexts from "../components/Texts/AllTexts";
import AdvancedSearch from "../components/Texts/AdvancedSearch/AdvancedSearch";
import useHttp from "../components/hooks/useHttp";
import { getAllTags, getAllTexts, saveText } from "../components/lib/api";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import TagsTab from "../components/Texts/Tags/TagsTab";
import { useParams } from "react-router-dom";

import AuthContext from "../store/auth-context";

const Texts = () => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  const { categoryId } = useParams();

  const {
    sendRequest: sendGetTextsRequest,
    status: getTextsRequestStatus,
    data: loadedTexts,
  } = useHttp(getAllTexts, true);
  const { sendRequest: sendSaveTextsRequest, status: saveTextRequestStatus } =
    useHttp(saveText);
  const {
    sendRequest: sendGetTagsRequest,
    status: getTagsRequestStatus,
    data: loadedTags,
  } = useHttp(getAllTags, true);

  useEffect(() => {
    let requestData = {
      token,
      skip: 0,
      limit: 1000
    }
    sendGetTextsRequest(requestData);
    sendGetTagsRequest(token);
  }, [sendGetTextsRequest, sendGetTagsRequest, token]);

  const [isChildPending, setIsChildPending] = useState(false);

  const saveTextHandler = (textId, name, description, content, tags, contentType, fileName) => {
    const requestData = {
      token,
      textId,
      name,
      description,
      content,
      tags,
      contentType,
      fileName,
    };
    sendSaveTextsRequest(requestData);
    const textToChange = loadedTexts.filter((item) => item.id === textId)[0];
    textToChange.name = name;
    textToChange.description = description;
    textToChange.content = content;
    textToChange.tags = tags;
    textToChange.contentType = contentType;
    textToChange.fileName = fileName;
  };

  const isPending =
    getTextsRequestStatus === "pending" ||
    getTagsRequestStatus === "pending" ||
    saveTextRequestStatus === "pending" ||
    isChildPending;

  const panes = [
    {
      menuItem: "Тесты",
      render: () => (
        <Tab.Pane>
          <AllTexts
            texts={loadedTexts}
            setIsPending={setIsChildPending}
            onSaveText={saveTextHandler}
            allTags={loadedTags}
            categoryId={categoryId}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Поиск по тексту",
      render: () => (
        <Tab.Pane>
          <AdvancedSearch
            texts={loadedTexts}
            setIsPending={setIsChildPending}
            allTags={loadedTags}
            onSaveText={saveTextHandler}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Рубрики",
      render: () => (
        <Tab.Pane>
          <TagsTab allTags={loadedTags} setIsPending={setIsChildPending} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <h1 className="page-title">Тексты</h1>
      <div>
        <Tab panes={panes} />
        {isPending && <LoadingSpinner />}
      </div>
    </>
  );
};

export default Texts;
