import Modal from "../../UI/Modal";
import Button from "../../UI/Button";

import classes from "./AddNewTag.module.css";
import { useRef } from "react";


const AddNewTag = (props) => {
  const valueRef = useRef();
  const textRef = useRef();

  const addHandler = () => {
    props.onAddTag(
      valueRef.current.value,
        textRef.current.value,
    );
  };


  return (
    <Modal onClose={props.onClose}>
      <div className={classes.control}>
        <label htmlFor="tag-value">Мнемокод</label>
        <input id="tag-value" type="text" ref={valueRef} />
        <label htmlFor="text">Описание</label>
        <input id="tag-text" type="text" ref={textRef} />
      </div>
      <div className={classes.actions}>
        <Button onClick={addHandler}>Add</Button>
        <Button additionalClass="cancel" onClick={props.onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AddNewTag;
