import classes from "./SingleNews.module.css"

const SingleNews = (props) => {
  return (
    <li key={props.id} className={classes.news}>
      <h2>{props.title}</h2>
      <p>{props.content}</p>
    </li>
  );
};

export default SingleNews;
