import SingleNews from "./SingleNews";

import classes from "./NewsList.module.css";

const DUMMY_NEWS = [
  {
    id: "news1",
    title: "Какая-то новость",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nibh diam, tincidunt eu orci a, mollis semper dolor. Aenean in.",
  },
  {
    id: "news2",
    title: "Ещё одна новость, похожая на первую",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus nunc non urna convallis tincidunt. Aliquam euismod, nibh a consectetur dignissim, justo nibh vestibulum augue, in blandit magna justo nec sem. In fringilla convallis rhoncus. Fusce felis lorem, congue in risus sit amet, dictum tincidunt sem. Quisque interdum odio non erat tincidunt, ac faucibus ex feugiat. Integer luctus nisi sed sem fringilla, suscipit eleifend purus sagittis. Cras placerat accumsan condimentum. Mauris vitae est nisl. Curabitur finibus arcu sit amet luctus condimentum. Nam magna mi, mollis ut sapien ac, tempor pulvinar sem. Nunc sit amet faucibus nibh. Vivamus finibus ex varius, luctus sapien ac, eleifend diam. Etiam nec elit at elit maximus porttitor mattis vitae nunc. Morbi consectetur lacinia semper. Proin.",
  },
  {
    id: "news3",
    title: "У нас много новостей!",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed ipsum in erat dapibus mattis sit amet a tortor. Nunc vitae blandit est. Praesent sed ligula nec purus rhoncus maximus rutrum id ante. Nam a est vitae turpis ornare semper. Suspendisse dictum tortor eget viverra pulvinar. Vivamus mauris enim, vestibulum at venenatis in, sagittis non neque. Vivamus justo massa, efficitur id tincidunt nec, rhoncus sit amet ipsum. Etiam ut leo justo. Nulla in tristique nisl. Aenean et laoreet tellus. Donec non eros a nibh vestibulum fringilla. Nullam ornare metus quis arcu interdum, at elementum magna fringilla. Morbi augue libero, sollicitudin sit amet tortor quis, viverra eleifend lectus. Proin lacinia lorem eu bibendum aliquet. Morbi erat urna, dapibus ut euismod in, blandit ut mauris.\nVivamus ultricies eu mauris nec tincidunt. Mauris a tellus vulputate, dictum eros et, cursus purus. Sed nisl tellus, lobortis non nisi non, pulvinar malesuada magna. In vel congue felis. Cras dolor ante, pharetra a turpis quis, scelerisque volutpat odio. Aenean elementum, dui quis suscipit sagittis, nunc lacus consectetur metus, sit amet commodo diam nibh in libero. Phasellus in tristique metus, sit amet fermentum nulla.\nNullam quis porttitor magna. Cras sodales elit eget lorem convallis, at gravida nibh consectetur. Curabitur eleifend nulla sed pretium condimentum. Quisque vulputate arcu non augue faucibus pulvinar. Nulla facilisi. Aenean viverra, sem et pretium dapibus, tortor augue varius quam, vel viverra ipsum erat eu mauris. Curabitur et consectetur velit, in hendrerit leo. Etiam volutpat malesuada nunc non luctus. Etiam eros arcu, hendrerit sit amet nunc quis, luctus cursus ante. Donec et lacinia quam. Sed ac ligula ornare, elementum sapien eu, facilisis nibh. Vestibulum placerat laoreet scelerisque. Phasellus vehicula fringilla pellentesque.",
  },
];

const NewsList = () => {
  return (
    <ul className={classes["news-list"]}>
      {DUMMY_NEWS.map((item) => {
        return (
          <SingleNews id={item.id} title={item.title} content={item.content} />
        );
      })}
    </ul>
  );
};

export default NewsList;
