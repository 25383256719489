import SingleLink from "../components/Links/SingleLink";

const DUMMY_LINKS = [
  {
    id: "link1",
    url: "http://google.com",
    title: "Google",
    description: "Самый знаменитый интернет-поисковик",
  },
  {
    id: "link2",
    url: "http://yandex.ru",
    title: "Yandex",
    description: "Отечетсвенный интернет-поисковик",
  },
  {
    id: "link3",
    url: "https://www.wikipedia.org/",
    title: "Wikipedia",
    description: "Знает каждый студент",
  },
];

const Links = () => {
  return (
    <section className="page-title">
      <h1>Ссылки</h1>
      <h3>Ссылки на связанные источники</h3>
      <ul className="unmark-list">
        {DUMMY_LINKS.map((item) => (
          <SingleLink id={item.id} url={item.url} description={item.description} title={item.title} />
        ))}
      </ul>
    </section>
  );
};

export default Links;
