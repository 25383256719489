import classes from "./SingleLink.module.css";

const SingleLink = (props) => {
  return (
    <li key={props.id} className={classes.links}>
      <a href={props.url} className="btn">{props.title}</a>
      <p>{props.description}</p>
    </li>
  );
};

export default SingleLink;
