import { useState, useEffect, useContext } from "react";

import AddNewText from "./AddNewText";
import AddText from "./AddText";
import EditText from "./EditText";
import SearchInput from "./SearchInput";
import TextsTable from "./TextsTable";

import useHttp from "../hooks/useHttp";
import { addText } from "../lib/api";

import AuthContext from "../../store/auth-context";

const categories = [
  { tag: "russian_novel", name: "Русский роман" },
  { tag: "russian_novelette", name: "Русский рассказ" },
  { tag: "narrative_prose", name: "Нарративная проза" },
  { tag: "interview", name: "Глубинные интервью" },
  { tag: "dataset", name: "Датасеты" },
  { tag: "bibliography", name: "Библиотеки" },
  { tag: "institutes", name: "Литературные институты" },
  { tag: "archive", name: "Архивы" },
  { tag: "lit_colloquium", name: "Литературный коллоквиум" },
  { tag: "gulag", name: "ГУЛАГ" },
];

const AllTexts = (props) => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchLine, setSearchLine] = useState("");
  const [tags, setTags] = useState([]);

  const itemsOnScreen = 10;
  let pagesCount = 1;
  let textsToShow = [];

  if (props.texts) {
    if (tags && tags.length > 0) {
      textsToShow = props.texts.filter((item) => {
        for (let i = 0; i < tags.length; i++) {
          if (!item.tags) return false;
          if (!item.tags.includes(tags[i])) return false;
        }
        return true;
      });
    } else {
      textsToShow = props.texts;
    }

    if (searchLine) {
      textsToShow = textsToShow.filter(
        (item) =>
          item.name.includes(searchLine) ||
          item.description.includes(searchLine)
      );
    }

    pagesCount = Math.ceil(textsToShow.length / itemsOnScreen);
    textsToShow = textsToShow.slice(
      (currentPage - 1) * itemsOnScreen,
      currentPage * itemsOnScreen
    );
  }

  const changePageHandler = (page) => {
    setCurrentPage(page);
  };

  const [isAddingNewText, setIsAddingNewText] = useState(false);
  const [isEditingText, setIsEditingText] = useState(false);

  const showAddNewText = () => {
    setIsAddingNewText(true);
  };

  const hideAddNewText = () => {
    setIsAddingNewText(false);
  };

  const showEditText = () => {
    setIsEditingText(true);
  };

  const hideEditText = () => {
    setIsEditingText(false);
  };

  const {
    sendRequest: sendAddRequest,
    status: addRequestStatus,
    data: newTextId,
  } = useHttp(addText);

  const addNewText = (
    name,
    description,
    content,
    tags,
    contentType,
    fileName
  ) => {
    setIsAddingNewText(false);

    const textData = {
      token,
      name,
      description,
      content,
      tags,
      contentType,
      fileName,
    };

    sendAddRequest(textData);
    props.texts.push({ ...textData, id: "addedText" });
  };

  const { setIsPending, texts, categoryId } = props;

  useEffect(() => {
    if (addRequestStatus === "pending") {
      setIsPending(true);
    } else {
      setIsPending(false);
      if (newTextId) {
        texts.filter((item) => item.id === "addedText")[0].id = newTextId;
      }
    }
  }, [addRequestStatus, setIsPending, newTextId, texts]);

  useEffect(() => {
    if (categoryId) {
      let desiredTags = [];
      const categoriesToOpen = categoryId.split("+");
      for (let i in categoriesToOpen) {
        desiredTags.push(categories[categoriesToOpen[i]]["tag"]);
      }

      searchHandler(desiredTags, searchLine);
    }
  }, [categoryId, searchLine]);

  // let previousSearchLine = "";
  const searchHandler = (tags, searchInput) => {
    setTags(tags);
    setSearchLine(searchInput);
    // if (searchInput === previousSearchLine) return;
    // previousSearchLine = searchInput;
  };

  const [textId, setTextId] = useState(null);
  const onRowClickHandler = (textId) => {
    setTextId(textId);
    showEditText();
  };

  return (
    <>
      <section id="search" className="column">
        <SearchInput
          onSearch={searchHandler}
          allTags={props.allTags}
          defaultTags={tags}
        />
      </section>
      <section id="text-list" className="column pointer">
        <TextsTable
          texts={textsToShow}
          pagesCount={pagesCount}
          currentPage={currentPage}
          onChangePage={changePageHandler}
          column1header="Название"
          column2header="Описание"
          column1name="name"
          column2name="description"
          onRowClick={onRowClickHandler}
        />
      </section>
      <section id="add-text" className="column">
        <AddText onClick={showAddNewText} />
      </section>
      {isAddingNewText && (
        <AddNewText
          onClose={hideAddNewText}
          onAddText={addNewText}
          allTags={props.allTags}
        />
      )}
      {isEditingText && (
        <EditText
          onClose={hideEditText}
          textId={textId}
          texts={props.texts}
          onSaveText={props.onSaveText}
          allTags={props.allTags}
        />
      )}
    </>
  );
};

export default AllTexts;
