//const MONGO_SERVER_DOMAIN = "http://localhost:8001";
const MONGO_SERVER_DOMAIN = "http://51.250.91.134:8001";

export async function taskResult(token, taskId) {
  let task_result_response = await fetch(
    `${MONGO_SERVER_DOMAIN}/task-result?token=${token}&task_id=${taskId}}`
  );
  let data = await task_result_response.json();
  while (data.state === "PENDING") {
    await new Promise((r) => setTimeout(r, 1000));
    task_result_response = await fetch(
      `${MONGO_SERVER_DOMAIN}/task-result?token=${token}&task_id=${taskId}`
    );
    data = await task_result_response.json();
  }
  return data.taskResult;
}

export async function getAllTexts(requestData) {
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/texts?token=${requestData["token"]}&skip=${requestData["skip"]}&limit=${requestData["limit"]}`);
  const taskId = await response.json();
  return await taskResult(requestData["token"], taskId);
}

export async function getText(requestData) {
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/text?token=${requestData["token"]}&text_id=${requestData["textId"]}`);
  const taskId = await response.json();
  return await taskResult(requestData["token"], taskId);
}

export async function addText(requestData) {
  const item = {
    token: requestData["token"],
    name: requestData["name"],
    description: requestData["description"],
    tags: requestData["tags"],
    content: requestData["content"],
    contentType: requestData["contentType"],
    fileName: requestData["fileName"],
  };
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/text`, {
    method: "POST",
    body: JSON.stringify(item),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const taskId = await response.json();
  return await taskResult(requestData["token"], taskId);
}

export async function saveText(requestData) {
  const item = {
    token: requestData["token"],
    name: requestData["name"],
    description: requestData["description"],
    tags: requestData["tags"],
    content: requestData["content"],
    contentType: requestData["contentType"],
    fileName: requestData["fileName"],
    textId: requestData["textId"],
  };
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/text`, {
    method: "PUT",
    body: JSON.stringify(item),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const taskId = await response.json();
  return await taskResult(requestData["token"], taskId);
}

export async function getAllTags(token) {
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/tags?token=${token}`);
  const taskId = await response.json();
  return await taskResult(token, taskId);
}

export async function addTag(requestData) {
  const item = {
    token: requestData["token"],
    value: requestData["value"],
    text: requestData["text"]
  };
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/tag`, {
    method: "POST",
    body: JSON.stringify(item),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const taskId = await response.json();
  return await taskResult(requestData["token"], taskId);
}

export async function uploadFile(requestData) {
  var formData = new FormData();
  formData.append("file", requestData["file"], requestData["fileName"])
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/upload?token=${requestData["token"]}`, {
    method: "POST",
    body: formData,
  });
  const respones_json = await response.json();
  if (respones_json["code"] === 200) {
    return respones_json["info"];
  }
  else {
    return "error";
  }
}

export async function downloadFile(requestData) {
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/download?fileName=${requestData["fileName"]}&token=${requestData["token"]}`);
  return await response.blob();
}

export async function findLineInTexts(requestData) {
  const response = await fetch(`${MONGO_SERVER_DOMAIN}/text/find-line?token=${requestData["token"]}&line=${requestData["line"]}`);
  const taskId = await response.json();
  return await taskResult(requestData["token"], taskId);
}
