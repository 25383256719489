import classes from "./SinglePublication.module.css";

const SinglePublication = (props) => {
  return (
    <li key={props.id} className={classes.publication}>
      <h2>{props.title}</h2>
      <h3>{props.authors}</h3>
      <p>{props.reference}</p>
    </li>
  );
};

export default SinglePublication;
