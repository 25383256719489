import SingleEvent from "./SingleEvent";

import classes from "./Events.module.css";

const DUMMY_NEWS = [
  {
    id: "event1",
    type: "Конференция",
    title: "Литература и общество: компьютерная платфома Социолит",
    description: "13.02 в 15 час. в ауд. F301, Покровский бул. 11 состоится отчетная конференция БП «Литература и общество: компьютерная платфома Социолит",
  },
  {
    id: "event2",
    type: "Конференция",
    title: "«Литературная учеба» М. Горького: неизвестные учителя и незнакомые ученики",
    description: "10.11 в 19 час. состоится доклад Валерия Вьюгина «Литературная учеба» М. Горького: неизвестные учителя и незнакомые ученики",
  },
];

const Events = () => {
  return (
    <ul className={classes["events-list"]}>
      {DUMMY_NEWS.map((item) => {
        return (
          <SingleEvent id={item.id} title={item.title} description={item.description} type={item.type} />
        );
      })}
    </ul>
  );
};

export default Events;
