import Modal from "../UI/Modal";
import Button from "../UI/Button";
import LoadingSpinner from "../UI/LoadingSpinner";

import classes from "./AddNewText.module.css";
import { useRef, useState, useContext, useEffect } from "react";
import { Dropdown, Radio } from "semantic-ui-react";

import useHttp from "../hooks/useHttp";
import { uploadFile } from "../lib/api";
import AuthContext from "../../store/auth-context";

import { Viewer, Worker } from "@react-pdf-viewer/core";

const escapeFileName = (fileName) => {
  let newFileName = fileName.replace("#", "");
  return newFileName;
}

const AddNewText = (props) => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  const nameRef = useRef();
  const descriptionRef = useRef();
  const contentRef = useRef();
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [pdfFile, setPdfFile] = useState();
  const [tags, setTags] = useState([]);
  const [contentType, setContentType] = useState("text");

  const contentTypeRadioHandler = (event, { value }) => {
    setContentType(value);
  };

  const addHandler = () => {
    let contentValue = "";
    if (contentRef.current) {
      contentValue = contentRef.current.value;
    }
    props.onAddText(
      nameRef.current.value,
      descriptionRef.current.value,
      contentValue,
      tags,
      contentType,
      fileName
    );
  };

  const chooseFileHandler = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPdfFile(URL.createObjectURL(selectedFile));
    }
  };

  const {
    sendRequest: sendUploadFileRequest,
    status: uploadFileRequestStatus,
    data: uploadFileName,
  } = useHttp(uploadFile);

  const isPending = uploadFileRequestStatus === "pending";

  const uploadHandler = () => {
    const uploadFileData = {
      token,
      file,
      fileName: escapeFileName(file["name"]),
    };
    sendUploadFileRequest(uploadFileData);
  };

  useEffect(() => {
    if (uploadFileRequestStatus !== "pending" && uploadFileName) {
      setFileName(uploadFileName);
    }
  }, [uploadFileRequestStatus, uploadFileName]);

  const deleteHandler = () => {
    setFileName("");
    setPdfFile(null);
  };

  const dropdownChangeHandler = (event, { value }) => {
    setTags(value);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className={classes.control}>
        <label htmlFor="name">Название</label>
        <input id="name" type="text" ref={nameRef} />
        <label htmlFor="description">Описание</label>
        <input id="description" type="text" ref={descriptionRef} />
        <label htmlFor="tags">Рубрики</label>
        <Dropdown
          id="tags"
          placeholder="Рубрики"
          fluid
          multiple
          search
          selection
          options={props.allTags}
          onChange={dropdownChangeHandler}
        />
      </div>
      <div className={classes.control}>
        <Radio
          name="contentType"
          label="Текст"
          value="text"
          checked={contentType === "text"}
          onChange={contentTypeRadioHandler}
          className={classes.radio}
        />
        <Radio
          name="contentType"
          label="PDF"
          value="pdf"
          checked={contentType === "pdf"}
          onChange={contentTypeRadioHandler}
          className={classes.radio}
        />
        <Radio
          name="contentType"
          label="File"
          value="file"
          checked={contentType === "file"}
          onChange={contentTypeRadioHandler}
          className={classes.radio}
        />
      </div>
      {isPending && <LoadingSpinner />}
      {contentType === "text" && (
        <div className={classes.control}>
          <label htmlFor="text">Текст</label>
          <textarea id="text" ref={contentRef} />
        </div>
      )}
      {contentType === "pdf" && (
        <div className={classes.control}>
          <div className={classes["flex-label"]}>
            <label htmlFor="chooseFile">Выберите файл для добавления: </label>
            <input
              id="chooseFile"
              name="chooseFile"
              type="file"
              onChange={chooseFileHandler}
            />
            <Button onClick={uploadHandler}>UPLOAD</Button>
          </div>
          <div className={classes["flex-label"]}>
            <label htmlFor="fileName">Имя добавленного файла: </label>
            <input
              id="fileName"
              name="fileName"
              type="text"
              readOnly
              value={fileName}
            />
            <a href={pdfFile} target="_blank" rel="noreferrer" download={fileName} className={classes["download-button"]}>DOWNLOAD</a>
            <Button onClick={deleteHandler}>DELETE</Button>
          </div>
          {pdfFile && (
            <div className={classes["control-div"]}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfFile} />
              </Worker>
            </div>
          )}
        </div>
      )}
      {contentType === "file" && (
        <div className={classes.control}>
          <div className={classes["flex-label"]}>
            <label htmlFor="chooseFile">Выберите файл для добавления: </label>
            <input
              id="chooseFile"
              name="chooseFile"
              type="file"
              onChange={chooseFileHandler}
            />
            <Button onClick={uploadHandler}>UPLOAD</Button>
          </div>
          <div className={classes["flex-label"]}>
            <label htmlFor="fileName">Имя добавленного файла: </label>
            <input
              id="fileName"
              name="fileName"
              type="text"
              readOnly
              value={fileName}
            />
            <a href={pdfFile} target="_blank" rel="noreferrer" download={fileName} className={classes["download-button"]}>DOWNLOAD</a>
            <Button onClick={deleteHandler}>DELETE</Button>
          </div>
        </div>
      )}

      <div className={classes.actions}>
        <Button onClick={addHandler}>Add</Button>
        <Button additionalClass="cancel" onClick={props.onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AddNewText;
