import { useState, useEffect, useContext } from "react";

import SearchInput from "../SearchInput";
import TextsTable from "../TextsTable";
import EditText from "../EditText";

import useHttp from "../../hooks/useHttp";
import { findLineInTexts } from "../../lib/api";

import AuthContext from "../../../store/auth-context";

const AdvancedSearch = (props) => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  const [currentPage, setCurrentPage] = useState(1);
  const [tags, setTags] = useState([]);
  const [isEditingText, setIsEditingText] = useState(false);
  
  const { setIsPending } = props;

  const {
    sendRequest: sendFindLineInTextsRequest,
    status: findLineInTextsRequestStatus,
    data: findedTexts,
  } = useHttp(findLineInTexts);

  useEffect(() => {
    if (findLineInTextsRequestStatus === "pending") {
      setIsPending(true);
    } else {
      setIsPending(false);
    }
  }, [findLineInTextsRequestStatus, setIsPending]);

  const itemsOnScreen = 10;
  let pagesCount = 1;
  let textsToShow = [];
  if (props.texts) {
    if (findedTexts) {
      for (let i = 0; i < findedTexts.length; i++) {
        let to_continue = true;
        if (tags && tags.length > 0) {
          for (let j = 0; j < tags.length; j++) {
            if (!findLineInTexts[i].tags.includes(tags[j])) {
              to_continue = false;
            }
          }
        }
        if (to_continue) {
          textsToShow.push({
            name: findedTexts[i].name,
            foundedLine: findedTexts[i].context,
            id: findedTexts[i].key,
            text_id: findedTexts[i].id
          });
        }
      }
    }
    pagesCount = Math.ceil(textsToShow.length / itemsOnScreen);
    textsToShow = textsToShow.slice(
      (currentPage - 1) * itemsOnScreen,
      currentPage * itemsOnScreen
    );
  }

  const changePageHandler = (page) => {
    setCurrentPage(page);
  };

  const searchHandler = (tags, searchInput) => {
    setTags(tags);
    
    const textData = {
      token,
      line: searchInput
    };

    sendFindLineInTextsRequest(textData);
  };

  const showEditText = () => {
    setIsEditingText(true);
  };

  const hideEditText = () => {
    setIsEditingText(false);
  };
  
  const [textId, setTextId] = useState(null);
  const onRowClickHandler = (rowId) => {
    debugger;
    setTextId(findedTexts.find(item => item.key === rowId)["_id"]);
    showEditText();
  };

  return (
    <>
      <section id="search" className="column">
        <SearchInput onSearch={searchHandler} allTags={props.allTags} defaultTags="" />
      </section>
      <section id="text-list" className="column pointer">
        <TextsTable
          texts={textsToShow}
          pagesCount={pagesCount}
          currentPage={currentPage}
          onChangePage={changePageHandler}
          column1header="Название"
          column2header="Найденный текст"
          column1name="name"
          column2name="foundedLine"
          onRowClick={onRowClickHandler}
        />
      </section>
      {isEditingText && (
        <EditText
          onClose={hideEditText}
          textId={textId}
          texts={props.texts}
          onSaveText={props.onSaveText}
          allTags={props.allTags}
        />
      )}
    </>
  );
};

export default AdvancedSearch;
