import Button from "../UI/Button";

import classes from "./AddText.module.css";

const AddText = (props) => {
  return (
    <div className={classes.div}>
      <Button onClick={props.onClick}>Add New</Button>
    </div>
  );
};

export default AddText;
