import React from "react";
// import htmlContent from '../../assets/IFORA/Interface.html'
// import { Parser } from 'html-to-react'


//{Parser().parse(htmlContent)}

const Ifora = () => {
  return (
    <>
      <iframe title="IFORA" src="http://51.250.91.134:3000/" width="100%" height="800" />
    </>
  );
};

export default Ifora;
