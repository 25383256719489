import React from "react";

const Structure = () => {
  return (
    <div>
      <h2>
        БОЛЬШОЙ ПРОЕКТ «РУССКАЯ ЛИТЕРАТУРА В СОЦИАЛЬНОМ ИЗМЕРЕНИИ: КОМПЬЮТЕРНАЯ
        Платформа Социолит»
      </h2>

      <p>
        <b>Руководитель проекта:</b> Казарцев Евгений Вячеславович, профессор
        Школы филологических наук
      </p>

      <p>
        <b>Научный руководитель проекта:</b> Калинин Илья Александрович,
        профессор департамента филологии НИУ ВШЭ Санкт-Петербург
      </p>

      <p>
        <b>Руководители подпроектов:</b>
        <ul>
          <li>
            Абелюк Евгения Семеновна, заведующая лабораторией по изучению
            творчества Юрия Любимова и режиссерского театра XX-XXI вв.
          </li>
          <li>Вдовин Алексей Владимирович, доцент Школы филологических наук</li>
          <li>
            Калинин Илья Александрович, профессор департамента филологии НИУ ВШЭ
            Санкт-Петербург
          </li>
          <li>
            Котельников Максим Павлович, стажер-исследователь Центра социологии
            культуры
          </li>
          <li>
            Кучерская Майя Александровна, профессор Школы филологических наук
          </li>
          <li>
            Луговская Дарья Алексеевна, преподаватель Школы филологических наук
          </li>
          <li>
            Пащенко Тарас Валерьевич, заведующий лабораторией проектирования
            содержания образования
          </li>
          <li>Пенская Елена Наумовна, профессор Школы филологических наук</li>
          <li>Полян Павел Маркович, директор Центра</li>
          <li>
            Шерстинова Татьяна Юрьевна, доцент департамента филологии НИУ ВШЭ
            Санкт-Петербург
          </li>
        </ul>
      </p>

      <h3>Разработка системы Социолит</h3>

      <p>
        Вашченков Виктор Эдуардович, программист Лаборатории комплексных
        дисциплинарных исследований
      </p>

      <h3>
        Проектная лаборатория по изучению творчества Юрия Любимова и
        режиссерского театра XX-XXI вв.
      </h3>

      <p>
        <b>Руководитель:</b> Абелюк Евгения Семеновна, заведующая лабораторией
        по изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв.
      </p>

      <p>
        <b>Научный Руководитель:</b> Пенская Елена Наумовна, профессор Школы
        филологических наук
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Кузовкин Геннадий Валерьевич, главный эксперт лаборатории по
            изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв.
          </li>
          <li>
            Левченко Татьяна Викторовна, главный эксперт лаборатории по изучению
            творчества Юрия Любимова и режиссерского театра XX-XXI вв.
          </li>
          <li>
            Леенсон Елена Ильинична, главный эксперт лаборатории по изучению
            творчества Юрия Любимова и режиссерского театра XX-XXI вв.
          </li>
          <li>
            Маньковская Серафина Аркадьевна, главный эксперт лаборатории по
            изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв.
          </li>
          <li>
            Теплякова Елена Анатольевна, менеджер лаборатории по изучению
            творчества Юрия Любимова и режиссерского театра XX-XXI вв.
          </li>
        </ul>
      </p>

      <h3>Мандельштамовский центр</h3>

      <p>
        <b>Руководитель:</b> Полян Павел Маркович, директор Центра
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>Видгоф Леонид Михайлович, эксперт Мандельштамовский центра</li>
          <li>Зуев Дмитрий Владимирович, эксперт Мандельштамовский центра</li>
        </ul>
      </p>

      <h3>
        Подпроект «Универсальный цифровой комментарий. Модель (на примере
        произведений советской литературы 1920-х годов)»
      </h3>

      <p>
        <b>Руководитель:</b> Абелюк Евгения Семеновна, заведующая лабораторией
        по изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв.
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Бонч-Осмоловская Анастасия Александровна, доцент Школы лингвистики
          </li>
          <li>Чамина Надежда Юрьевна, доцент Школы исторических наук</li>
        </ul>
      </p>

      <h3>Подпроект «Национальный корпус русского романа»</h3>

      <p>
        <b>Руководитель:</b> Вдовин Алексей Владимирович, доцент Школы
        филологических наук
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Клышинский Эдуард Станиславович, доцент Школы филологических наук
          </li>
          <li>
            Олещук Анастасия Вадимовна, Специалист по учебно-методической работе
            отдела сопровождения учебного процесса в бакалавриате
          </li>
          <li>Орехов Борис Валерьевич, доцент Школы лингвистики</li>
          <li>
            Маслинский Кирилл Александрович, старший научный сотрудник
            Лаборатории комплексных дисциплинарных исследований
          </li>
          <li>
            Челнокова Дарья Александровна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
        </ul>
      </p>

      <h3>
        Подпроект «Культурная революция 1920–1930 годов и практики производства
        советского субъекта»
      </h3>

      <p>
        <b>Руководитель:</b> Калинин Илья Александрович, профессор департамента
        филологии НИУ ВШЭ Санкт-Петербург
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Кочнева Татьяна Анатольевна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Попов Степан Денисович, стажер-исследователь Лаборатории комплексных
            дисциплинарных исследований
          </li>
        </ul>
      </p>

      <h3>
        Подпроект «Социальная топология литературных практик: анализ
        интерпретативных и эмоциональных режимов чтения»
      </h3>

      <p>
        <b>Руководитель:</b> Котельников Максим Павлович, стажер-исследователь
        Центра социологии культуры
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>Куракин Дмитрий Юрьевич, директор Центра социологии культуры</li>
          <li>
            Лукина Анастасия Андреевна, младший научный сотрудник Центра
            социологии культуры
          </li>
          <li>Гаджиев Акиф, стажер-исследователь Центра социологии культуры</li>
          <li>
            Толстых Дарья Сергеевна, стажер-исследователь Центра социологии
            культуры
          </li>
          <li>
            Павленко Екатерина Сергеевна, старший преподаватель департамента
            образовательных программ
          </li>
          <li>Романенко Ксения Романовна, доцент Центра социологии культуры</li>
        </ul>
      </p>

      <h3>
        Подпроект «От школьной скамьи до лагерных нар: русский литературный
        канон в массовом сознании XX века (диктанты, словари, воспоминания
        узников ГУЛАГа»
      </h3>

      <p>
        <b>Руководитель:</b> Луговская Дарья Алексеевна, преподаватель Школы
        филологических наук
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>Успенский Павел Федорович, доцент Школы филологических наук</li>
          <li>
            Агеева Алиса Алексеевна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Дынина Александра Данииловна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Гремякина Мария Сергеевна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
        </ul>
      </p>

      <h3>
        Подпроект «Литературно-образовательные сообщества в Советской России
        1910–1930-х годов: техники и практики творческого письма»
      </h3>

      <p>
        <b>Руководитель:</b> Кучерская Майя Александровна, профессор Школы
        филологических наук
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>Свердлов Михаил Игоревич, доцент Школы филологических наук</li>
          <li>
            Чабан Александра Александровна, старший преподаватель Школы
            филологических наук
          </li>
          <li>
            Поселягин Николай Владимирович, доцент Школы филологических наук
          </li>
          <li>
            Каверкина Александра Максимовна, менеджер факультета гуманитарных
            наук
          </li>
          <li>
            Банников Денис Денисович, старший преподаватель Школы филологических
            наук
          </li>
        </ul>
      </p>

      <h3>
        Подпроект «Исследования подходов и практик преподавания литературы на
        постсоветском пространстве»
      </h3>

      <p>
        <b>Руководитель:</b> Пащенко Тарас Валерьевич, заведующий лабораторией
        проектирования содержания образования
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Авдеенко Надежда Александровна, аналитик Института образования
          </li>
          <li>Красовская Светлана Игоревна, преподаватель Лицея НИУ ВШЭ</li>
          <li>
            Ченцова Александра Александровна, аналитик лаборатории
            проектирования содержания образования
          </li>
        </ul>
      </p>

      <h3>
        Подпроекты "Театр и литература. Социокультурные трансформации
        взаимодействия в условиях глобальной цифровизации общества"; "Культурное
        наследие: архивы, музеи – тематическое моделирование и инструменты
        трансляции литературы"; "Медиалогия и медиаграфия советского
        литературного андеграунда"
      </h3>
      <p>
        <b>Руководитель:</b> Пенская Елена Наумовна, профессор Школы
        филологических наук
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Яворовская Надежда Владиславовна, ассистент Школы филологических
            наук
          </li>
          <li>
            Зинченко Анастасия Андреевна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Короткова Софья Владиславовна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Пискунова Екатерина Андреевна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Голубенко Наталия, стажер-исследователь Лаборатории комплексных
            дисциплинарных исследований
          </li>
          <li>
            Калмыкова Вера Владимировна, научный сотрудник Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>
            Мисникевич Татьяна Владимировна, научный сотрудник Школы
            филологических наук
          </li>
          <li>Павловец Михаил Георгиевич, доцент Школы филологических наук</li>
          <li>
            Дорогов Михаил Константинович, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
          <li>Хачатурян Любовь Валерьевна, доцент Школы филологических наук</li>
          <li>
            Родионова Анна Владимировна, стажер-исследователь Лаборатории
            комплексных дисциплинарных исследований
          </li>
        </ul>
      </p>

      <h3>
        Подпроект «Социальные катаклизмы общества и статистические
        характеристики малой русской прозы: ХХ век»
      </h3>

      <p>
        <b>Руководитель:</b> Шерстинова Татьяна Юрьевна, доцент департамента
        филологии НИУ ВШЭ Санкт-Петербург
      </p>

      <p>
        <b>Сотрудники:</b>
        <ul>
          <li>
            Кирина Маргарита Александровна, приглашенный преподаватель
            департамента филологии НИУ ВШЭ Санкт-Петербург
          </li>
          <li>
            Москвина Анна Денисовна, преподаватель департамента филологии НИУ
            ВШЭ Санкт-Петербург
          </li>
          <li>Узденова Алена Дмитриевна</li>
          <li>Лукьянчикова Алиса Сергеевна</li>
          <li>Родионов Руслан Андреевич</li>
          <li>Колпащикова Евгения Олеговна</li>
        </ul>
      </p>

      <h3>
        Подпроект «Роль библиотек в формировании читательских интересов в
        советское время»
      </h3>

      <p>
        <b>Руководитель:</b> Казарцева Екатерина Витальевна, приглашенный
        специалист
      </p>

      <p>
        <b>Приглашенные специалисты:</b>
        <ul>
          <li>Харитонов Дмитрий Владимирович</li>
          <li>Куницын Георгий Владимирович</li>
          <li>Бурденева Елизавета Андреевна</li>
          <li>Левина Мария Дмитриевна</li>
          <li>Королева Ангелина Максимовна</li>
          <li>Вьюгин Валерий Юрьевич</li>
          <li>Савицкий Станислав Анатольевич</li>
          <li>Нечаева Ольга Игоревна</li>
          <li>Давыдова Евгения Михайловна</li>
        </ul>
      </p>
    </div>
  );
};

export default Structure;
